/* @import url('./assets/fonts/Inter/Inter-VariableFont_opsz\,wght.ttf'); */
:root {
  background: rgb(183, 236, 195);
  background: linear-gradient(65deg,
      rgba(183, 236, 195, 1) 0%,
      rgba(242, 236, 239, 1) 36%,
      rgba(242, 236, 239, 1) 74%,
      rgba(183, 236, 195, 1) 100%);
  height: 100vh;
  width: 100vw;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter/Inter-VariableFont_opsz\,wght.ttf) format('woff');
}

::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  opacity: 1;
  overflow-y: scroll;
  margin: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
  font-family: "Inter", sans-serif;
}

li {
  list-style: none;
}

h4 {
  font-family: "Inter", sans-serif;
  font-size: 1.275rem;
  color: var(--bs-emphasis-color);
}

.btn-primary {
  border-radius: 12px;
  background-color: #79e945;
  color: #2e451d;
  border: none;
}

.btn-secondary {
  border-radius: 12px;
  background-color: #70ae66;
  color: #fff;
  border: none;
}

.btn-secondary:hover {
  border-radius: 12px;
  background-color: #70ae66;
  color: #fff;
  border: none;
}

.btn-primary.inactive {
  background-color: #bababa;
  color: #e3e3e3;
}

.header {
  padding: 1rem;
  display: flex;
  color: #888b95;
  justify-content: center;
  background-color: #393d4e;
}

.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 1rem;
  display: flex;
  color: #888b95;
  justify-content: center;
  background-color: #393d4e;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - (200px + 100px));
  padding: 30px;
  box-sizing: border-box;
}

/* Layout for admin dashboard skeleton */

.wrapper {
  align-items: stretch;
  display: flex;
  width: 100%;
  background: rgb(183, 236, 195);
  background: linear-gradient(65deg,
      rgba(183, 236, 195, 1) 0%,
      rgba(242, 236, 239, 1) 36%,
      rgba(242, 236, 239, 1) 74%,
      rgba(183, 236, 195, 1) 100%);
  height: 100vh;
  width: 100vw;
}

#sidebar {
  max-width: 264px;
  min-width: 264px;
  max-width: 60rem;
  height: 100vh;
  background-color: #fff;
  transition: all 0.35s ease-in-out;
  position: sticky;
  top: 0;
}

.nav-pills li a {
  display: flex;
  color: #2e451d;
}

.nav-pills li a:hover {
  color: white;
}

.nav-pills li a:hover {
  background-color: #5f9f54;
}

.nav-pills {
  --bs-nav-pills-link-active-bg: #5f9f54;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 0;
  overflow: scroll;
  transition: all 0.35s ease-in-out;
  width: 100%;
  background-color: #eee;
}

.navbar-expand .navbar-nav {
  margin-left: auto;
}

.content {
  flex: 1;
  max-width: 100vw;
  width: 100vw;
}

@media (min-width: 768px) {
  .content {
    width: auto;
  }
}

/* Sidebar Toggle */

#sidebar.collapsed {
  margin-left: -264px;
}

/* Footer and Nav */

@media (max-width: 767.98px) {
  .js-sidebar {
    margin-left: -264px;
  }

  #sidebar.collapsed {
    margin-left: 0;
  }

  .navbar,
  footer {
    width: 100vw;
  }
}

.dropzone {
  background-color: #e9e9e9;
  border: 1px solid #6ead65;
}

/* Tab Pages Start */
.tab ul {
  list-style-type: none;
}

.tab ul li {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  margin-right: 2rem;
  margin-left: 2rem;
  display: inline;
  font-weight: bold;
  color: #868686;
  text-align: center;
  background-color: transparent;
}

.tab ul li.active {
  display: inline;
  color: #74b36c;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #74b36c;
}

.tab ul li:hover {
  color: #4062a3;
  cursor: pointer;
}

.tab-content {
  display: none;
}

.show-content {
  display: block;
}

/* Tab Pages End */

/* /components/Register.js start */
/* .name {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 30px 0;
}

.name i {
  position: absolute;
  right: 20px;
  top: 50px;
} */

.icon {
  border-radius: 0px 10px 10px 0px;
  background-color: #5f9f54;
  color: #fff;
  margin: 0;
  font-size: 18px;
}

/* /components/Register.js end */

/* ForgotPassword.js start*/

/* ForgotPassword.js end*/

/* ForgotPasswordVerify.js start*/

.sendAgain {
  text-decoration: underline;
  color: #000000;
  cursor: pointer;
}

/* Searchsettings.js start*/
.zindex {
  z-index: 1;
  position: relative;
}

/* Searchsettings.js end*/